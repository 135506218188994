import { Controller } from "@hotwired/stimulus"
import Autolinker from "autolinker"

export default class extends Controller {
  connect() {
    const autolinkElements = this.element.querySelectorAll(".autolink")
    autolinkElements.forEach((element) => {
      element.innerHTML = Autolinker.link(element.innerHTML)
    });
  }
}