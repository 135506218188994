import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "field"];
  static values = {
    fieldValue: String
  }

  connect() {
    this.updateField() // Set initial value based on the checkbox state
  }

  updateField() {
    if (this.checkboxTarget.checked) {
      this.fieldTarget.value = this.fieldValueValue
    } else {
      this.fieldTarget.value = ""
    }
  }
}
